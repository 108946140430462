// HTML template for the custom element
const templateHTML = `
  <div class="talbya-card-container mb-3">
  
  </div>
`;

// Create a template element to store the structure
const template = document.createElement("template");
template.innerHTML = templateHTML;

export default class ETalbyaCard extends HTMLElement {
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    this.rows = null;
  }

  connectedCallback() {
    if (!ETalbyaCard.templateLoaded) {
      ETalbyaCard.template = template;
      ETalbyaCard.templateLoaded = true;
    }

    this._loadTemplate();
  }

  _loadTemplate() {
    const template = ETalbyaCard.template;

    if (template) {
      const url = window.location.pathname + window.location.search;
      const templateContent = template.content.cloneNode(true);

      this.appendChild(templateContent);
      this._showLoading();
      this._fetchData(url);
    } else {
      console.error("Template not found!");
    }
  }

  async _fetchData(url) {
    try {
      const config = {
        method: "GET",
        headers: {
          "X-CSRF-Token": window.csrf || "",
        },
      };

      const response = await fetch("api" + url, config);

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();

      if (data.rows.total > 0) {
        this._createCards(data);
        const loading = document.getElementById("loading");
        if (loading) loading.remove();

        if (data.rows.total / data.rows.per_page > data.rows.current_page) {
          this.appendChild(this._createNextButton());
        }
      } else {
        this._hideLoading();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this._hideLoading();
    } finally {
      // Optionally hide loading if needed
    }
  }

  _createCards(data) {
    let url = this.getAttribute("open");
    let report = this.getAttribute("report");

    data.rows.data.map((card) => {
      const cardElement = document.createElement("div");
      cardElement.className = "card mb-3";
      cardElement.innerHTML = `

			<div class="card-header d-flex justify-content-between align-items-center">
				<h3 class="card-title">${
          (card.gender == "ذكر"
            ? "<i class='bi fs-4 text-primary bi-gender-male'></i> السيد "
            : "<i class='bi  fs-4 text-primary bi-gender-female'></i> السيدة ") +
          card.name
        }</h3>
				<a href="${_setParams(report, {
          guide: card.guide,
        })}" target="_blank" class="btn text-danger fs-4">
					<i class="bi bi-exclamation-octagon"></i>
				</a>
			</div>
			<div class="row row-cols-1 row-cols-md-2 p-3">
				<div class="col">
					<ul class="list-group list-group-flush p-0">
						<li class="list-group-item">
							<strong>العمر:
							</strong>
							${card.age}
						</li>
						<li class="list-group-item">
							<strong>مكان :
							</strong>
							${card.city + ", " + card.district}
						</li>
						<li class="list-group-item">
							<strong>العمل :
							</strong>
							${card.service}, ${card.job}
						</li>
						<li class="list-group-item">
							للتواصل في ${card.available} على رقم ${card.mobile}
						</li>
					</ul>
				</div>
				<div class="col">
					<hr class="d-md-none">
					<h3>حول</h3>
					<p>${card.message}</p>
				</div>
			</div>
			<div class="card-footer">
				<a href="https://www.google.com/maps/search/?api=1&query=${card.lat},${
        card.lng
      }" target="_blank" class="btn text-primary">
					<i class="bi bi-geo-alt-fill"></i>
					عرض المكان</a>
				<a href="${_setParams(url, {
          uid: card.uid,
        })}" target="_blank" class="btn text-primary">
					<i class="bi bi-eye"></i>
					عرض البيانات</a>
			</div>`;

      this.appendChild(cardElement);
    });
  }

  _createNextButton() {
    const div = document.createElement("div");
    div.setAttribute("class", "text-center");

    const nextButton = document.createElement("button");
    nextButton.setAttribute("type", "button");
    nextButton.setAttribute("class", "btn btn-primary");
    nextButton.textContent = "المزيد";
    nextButton.addEventListener("click", () => {
      this._nextPage();
      div.innerHTML = `
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      `;
      setTimeout(() => {
        div.remove();
      }, 500);
    });

    div.appendChild(nextButton);

    return div;
  }

  _nextPage() {
    const baseUrl = this._getQueryParams();

    const url = this._updateURLParams({
      page: Number(baseUrl.page) + 1 || 2,
    });

    history.pushState(null, "", url);
    this._fetchData(url);
  }

  _showLoading() {
    this.innerHTML = `
  <table id="loading" class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">
          <p class="placeholder-wave">
            <span class="placeholder col-12"></span>
          </p>
        </th>
        <th scope="col">
          <p class="placeholder-wave">
            <span class="placeholder col-12"></span>
          </p>
        </th>
        <th scope="col">
          <p class="placeholder-wave">
            <span class="placeholder col-12"></span>
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="4">
          <p class="placeholder-wave">
            <span class="placeholder col-12"></span>
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <p class="placeholder-wave">
            <span class="placeholder col-12"></span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>`;
  }

  _hideLoading() {
    // Optionally hide loading if needed
    const create = this.getAttribute("new");
    this.innerHTML = `
     <div class="text-center">
     <h2>للاسف لا يتوفر خدمات في هذا المكان حالياً</h2>
     <a href="${create}">
     <button type="button" class="btn btn-primary">اضافة خدمة</button>
     </a>
     </div>`;
  }

  /**
   * Gets the query parameters from the current URL
   * @returns {Object} An object containing the query parameters
   */
  _getQueryParams() {
    const params = new URLSearchParams(document.URL.split("?")[1]); // Get the query part after ?
    const paramsObj = {};
    for (const [key, value] of params.entries()) {
      paramsObj[key] = value;
    }
    return paramsObj;
  }

  /**
   * Updates the current URL with new query parameters
   * @param {Object} params - An object containing the new query parameters
   * @returns {string} The updated URL as a string
   */
  _updateURLParams(params = {}) {
    const urlObj = new URL(document.URL); // This will now work with a complete URL

    // Update the URL with new parameters (like page or perPage)
    Object.keys(params).forEach((key) =>
      urlObj.searchParams.set(key, params[key])
    );

    return window.location.pathname + urlObj.search; // Return the updated URL as a string
  }
}
