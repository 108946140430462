// Define the HTML template for the SubmitButton component
const templateHTML = `
<button type="submit"
        class="submit-button ">
</button>
<button class="loading-button d-none "
        type="button"
        disabled>
  <span class="spinner-grow spinner-grow-sm"
        aria-hidden="true"></span>
  <span role="status">Loading...</span>
</button>
`;

// Create a template element to store the input structure
const template = document.createElement("template");
template.id = "e-submit-button-template";
template.innerHTML = templateHTML;

export default class SubmitButton extends HTMLElement {
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    // Initialize class properties
    this.submitButton = null;
    this.loadingButton = null;
    this.onClickFunctionName = null;
    this.isAuthorized = null;
  }

  connectedCallback() {
    // Load the template if it's not already loaded
    if (!SubmitButton.templateLoaded) {
      SubmitButton.template = template;
      SubmitButton.templateLoaded = true;
    }

    this._loadTemplate();
    this._setAttributes();
    this._applyEventListeners();
  }

  _loadTemplate() {
    const template = SubmitButton.template;

    if (template) {
      // Clone the template content and attach it to the custom element
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      // Store references to key elements within the template
      this.submitButton = this.querySelector(".submit-button");
      this.loadingButton = this.querySelector(".loading-button");
      this.onClickFunctionName = this.getAttribute("onclick") || false;
      this.isAuthorized = this.hasAttribute("authorized");
    } else {
      console.EError("Template not found!"); // Error handling if the template is missing
    }
  }

  _setAttributes() {
    if (this.hasAttribute("icon")) {
      const icon = document.createElement("span");
      icon.setAttribute("class", `bi ${this.getAttribute("icon")}`);
      this.submitButton.appendChild(icon);

      if (this.hasAttribute("name"))
        this.submitButton.innerHTML += " " + this.getAttribute("name");
    } else {
      this.submitButton.textContent = this.getAttribute("name") || "Submit";
    }

    if (this.hasAttribute("type")) {
      this.submitButton.type = this.getAttribute("type");
    }

    const defaultStyle = "btn btn-primary";

    if (this.hasAttribute("btnClass")) {
      this.submitButton.className +=
        this.getAttribute("btnClass") || defaultStyle;
      this.loadingButton.className +=
        this.getAttribute("btnClass") || defaultStyle;
    } else {
      this.submitButton.className += defaultStyle;
      this.loadingButton.className += defaultStyle;
    }

    this.submitButton.disabled = this.getAttribute("disabled") || false;
  }

  _applyEventListeners() {
    if (this.submitButton) {
      this.submitButton.addEventListener("click", (event) => {
        this._toggleLoadingState(true);

        let dom;
        if (event.srcElement.form) {
          event.preventDefault(); // Prevent default form submission
          dom = event.srcElement.form;
          dom.classList.add("needs-validation");
        } else {
          if (event.srcElement.parentElement.tagName == "BUTTON") {
            dom = event.srcElement.parentElement.parentElement;
          } else {
            dom = event.srcElement.parentElement;
          }
        }

        if (this._validateForm(dom)) {
          // Call the specified click handler function
          this._submit(dom, this.isAuthorized)
            .then((data) => {
              if (
                this.onClickFunctionName &&
                typeof window[this.onClickFunctionName] === "function"
              ) {
                window[this.onClickFunctionName](data);
              }

              this._showFeedbackMessage(
                data?.response?.message || "process successfully",
                "success"
              );
              this._toggleLoadingState(false);
            })
            .catch((error) => {
              this._showFeedbackMessage(error || "An error occurred", "danger");
              this._toggleLoadingState(false);
            });
        } else {
          this._toggleLoadingState(false);
        }
      });
    }
  }

  _validateForm(form) {
    const elements = form.querySelectorAll("[required]");
    let isValid = true;

    elements.forEach((element) => {
      if (!["INPUT", "SELECT", "TEXTAREA"].includes(element.tagName)) return; // stop processing this iteration;
      const errorSpan = element.parentElement.querySelector(".error-message");

      if (!element.value || (element.type === "checkbox" && !element.checked)) {
        isValid = false;
        element.classList.add("is-invalid");
        if (errorSpan) {
          errorSpan.classList.add("d-block");
          errorSpan.textContent =
            element.getAttribute("data-error-message") ||
            "This field is required.";
        }
      } else {
        element.classList.remove("is-invalid");
        if (errorSpan) {
          errorSpan.classList.remove("d-block");
          errorSpan.textContent = "";
        }
      }
    });

    if (isValid) {
      return true;
    } else {
      this._showFeedbackMessage(
        "Please correct the errors and try again.",
        "warning"
      );
      return false;
    }
  }

  _toggleLoadingState(isLoading) {
    if (isLoading) {
      this.submitButton.classList.add("d-none");
      this.loadingButton.classList.remove("d-none");
    } else {
      const original = this.submitButton.innerHTML;
      this.submitButton.innerHTML = `<i class="bi bi-check2-all"></i>`;
      this.submitButton.classList.remove("d-none");
      this.loadingButton.classList.add("d-none");

      setTimeout(() => {
        this.submitButton.innerHTML = original;
      }, 500);
    }
  }

  _showFeedbackMessage(message, color) {
    return new Toast(message, color);
  }

  async _submit(form = null, authorized = undefined, additionalConfig = {}) {
    const parent = form.querySelector("e-submit-button") || form;

    if (parent.hasAttribute("confirm")) {
      const ask =
        parent.getAttribute("confirm").length > 3
          ? parent.getAttribute("confirm")
          : "Are you sure you want to do this?";
      if (!confirm(ask)) throw "Process cancelled!";
    }

    try {
      const url = parent.getAttribute("action") || undefined;
      const method = parent.getAttribute("method") || "GET";
      const type = parent.getAttribute("enctype") || "application/json";

      if (!url) throw "URL not found!";
      const config = {
        method: method.toUpperCase(),
        headers: {
          "X-CSRF-Token": window.csrf || "",
          "Content-Type": type,
          ...additionalConfig.headers, // Include any additional headers passed
        },
        ...additionalConfig, // Include other additional configurations passed
      };

      if (authorized !== undefined) {
        config.headers["Authorization"] = window.token;
      }

      if (form.tagName === "FORM") {
        form.classList.add("was-validated");
        if (!form.checkValidity()) throw "The form is not completed!";

        if (method !== "GET" && form) {
          const formData = new FormData(form);
          const formObject = this._formDataToObject(formData);

          // Convert the object to a JSON string
          const data = JSON.stringify(formObject);

          // this option if need to encrypt the data
          // Encrypt the data (assuming _encryptData is a synchronous function)
          // const encryptedPayload = await _encryptData(data);
          // JSON.stringify({
          //   encryptedData: encryptedPayload.encryptedData,
          //   iv: encryptedPayload.iv,
          // }

          // config.body = data;
          config.body = data;
        }
      }

      const response = await fetch(url, config);

      const contentType = response.headers.get("content-type");
      if (!contentType) {
        throw "Content-Type header is missing";
      }

      let data;
      if (contentType.includes("application/json")) {
        data = await response.json();
      } else if (
        contentType.includes("text/html") ||
        contentType.includes("text/plain")
      ) {
        data = await response.text();
      } else {
        throw `Unsupported content type: ${contentType}`;
      }

      if (!response.ok) {
        if (typeof data === "object" && data.errors) {
          const valuesArray = Object.values(data.errors);
          throw valuesArray.join("\n");
        } else {
          throw (
            data.message ||
            `Network response was not ok: ${response.statusText}`
          );
        }
      }

      if (data.redirect) {
        // Uncomment this line to handle redirects
        if (data.redirect == "self") {
          window.location.reload();
        } else {
          setTimeout(() => {
            window.location.href = data.redirect;
          }, 1000);
        }
        return { response: data };
      } else if (response.status === 200 || response.status === 201) {
        if (form.tagName === "FORM") {
          form.classList.remove("was-validated");
          // form.reset();
        }
        return { response: data };
      } else {
        throw data;
      }
    } catch (error) {
      throw ("There was a problem with the request:", error || "Server 500");
    }
  }

  _formDataToObject(formData) {
    const object = {};

    formData.forEach((value, key) => {
      // Check if the key already exists
      if (object.hasOwnProperty(key)) {
        // If it's a file, create an array or append to existing array
        if (value instanceof File) {
          if (!Array.isArray(object[key])) {
            object[key] = [object[key]]; // Convert to array if not already
          }
          object[key].push(value);
        } else {
          // If it's not a file, append the value (comma-separated string for simplicity)
          object[key] = `${object[key]},${value}`;
        }
      } else {
        // If the key doesn't exist, add it directly
        object[key] = value;
      }
    });

    return object;
  }
}
