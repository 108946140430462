// Define the HTML template for the custom element
const templateHTML = `
<div class="image-container m-0 m-auto">
    <div class="loading-placeholder">
        <div role="status" class="spinner-grow">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <picture class="responsive-picture">
        <img class="lazy-load img-fluid" />
    </picture>
    <figcaption class="image-caption d-none"></figcaption>
</div>
`;

const template = document.createElement("template");
template.id = "e-image-template";
template.innerHTML = templateHTML;

export default class EImage extends HTMLElement {
  static templateLoaded = false;

  constructor() {
    super();
    this.img = null;
    this.loadingPlaceholder = null;
  }

  connectedCallback() {
    if (!EImage.templateLoaded) {
      EImage.template = template;
      EImage.templateLoaded = true;
    }
    this._loadTemplate();
  }

  _loadTemplate() {
    const template = EImage.template;
    if (template) {
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      this.img = this.querySelector("img");
      this.loadingPlaceholder = this.querySelector(".loading-placeholder");
      this.pictureElement = this.querySelector("picture");
      this.captionElement = this.querySelector(".image-caption");

      this._setAttributes();
      this._initIntersectionObserver();
    } else {
      console.error("Template not found!");
    }
  }

  _setAttributes() {
    const width = this.getAttribute("width");
    const height = this.getAttribute("height");
    const author = this.getAttribute("author");
    const caption = this.getAttribute("caption");
    const sources = this.getAttribute("sources");

    if (width) this.loadingPlaceholder.style.width = `${width}px`;
    this.loadingPlaceholder.style.height = height ? `${height}px` : "100%";

    this.img.dataset.src = this.getAttribute("src");
    this.img.alt =
      this.getAttribute("alt") || "Image description not available";
    if (author) this.img.setAttribute("data-author", author);

    if (caption) {
      this.captionElement.textContent = caption;
      this.captionElement.classList.remove("d-none");
    }

    const classesToAdd = this.getAttribute("class");
    if (classesToAdd) {
      this.img.classList.add(...classesToAdd.split(" "));
    }

    const ariaLabel = this.getAttribute("aria-label") || this.img.alt;
    this.img.setAttribute("aria-label", ariaLabel);

    if (this.hasAttribute("title")) {
      this.img.title = this.getAttribute("title");
    }

    this.img.id =
      this.getAttribute("id") ||
      `e-image-${Math.floor(Math.random() * 100000)}`;
    this.img.setAttribute("tabindex", "0");

    const container = this.querySelector(".image-container");
    container.style.position = "relative";
    container.style.overflow = "hidden";

    if (width && height) {
      container.style.width = `${width}px`;
      container.style.height = `${height}px`;
      this.img.style.width = "100%";
      this.img.style.height = "100%";
    } else if (width) {
      container.style.width = `${width}px`;
      container.style.height = "auto";
      this.img.style.width = "100%";
      this.img.style.height = "auto";
    } else if (height) {
      container.style.height = `${height}px`;
      container.style.width = "auto";
      this.img.style.width = "auto";
      this.img.style.height = "100%";
    } else {
      container.style.width = "100%";
      container.style.height = "auto";
      this.img.style.width = "100%";
      this.img.style.height = "auto";
    }

    if (sources) {
      this._setPictureSources(JSON.parse(sources));
    }

    this.img.style.objectFit = "cover";
    this.img.loading = "lazy"; // Enable native lazy loading
  }

  _setPictureSources(sources) {
    if (Array.isArray(sources) && sources.length) {
      sources.forEach((source) => {
        const sourceElement = document.createElement("source");
        sourceElement.srcset = source.srcset;
        sourceElement.media = source.media || "";
        sourceElement.type = source.type || "";
        this.pictureElement.insertBefore(sourceElement, this.img);
      });
    }
  }

  _initIntersectionObserver() {
    if (!this.img) {
      console.error("imgElement is not defined or found in the DOM.");
      return;
    }

    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this._loadImage();
            observer.unobserve(this.img);
          }
        });
      });
      observer.observe(this.img);
    } else {
      this._loadImage();
    }
  }

  _loadImage() {
    this.img.src = this.img.dataset.src;

    this.img.onload = () => {
      this.loadingPlaceholder.remove();
      this.img.loading = "lazy";
    };

    this.img.onerror = () => {
      console.error(`Failed to load image ${this.img.src}`);
      this.loadingPlaceholder.innerHTML = `Failed to load image ${this.img.src}`;
      this.img.style.display = "none";
    };
  }
}
