// HTML template for the custom element
const templateHTML = `
  <div class="eselect-options-container mb-3">
    <label class="select-label text-capitalize">المحافظة</label>
    <select id="city-select" class="select-dropdown form-select" aria-label="Default select example" >
      <option value="">تحديد المحافظة</option>
    </select>
    <span class="hint-field form-text"></span>
    <span class="error-message"></span>
  </div>
  <div class="eselect-options-container mb-3" style="display: none;">
    <label class="select-label text-capitalize">المنطقة</label>
    <select id="location-select" class="select-dropdown form-select" aria-label="Default select example">
      <option value="">تحديد المنطقة</option>
    </select>
    <span class="hint-field form-text"></span>
    <span class="error-message"></span>
  </div>
  <input type="hidden" id="zone" name="zone">
`;

// Create a template element to store the structure
const template = document.createElement("template");
template.innerHTML = templateHTML;

import { getCity, getDistricts, getDistrict, getCityByName } from "./cities.js";

export default class EZone extends HTMLElement {
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    this.cities = null;
    this.citySelect = null;
    this.districtsSelect = null;
    this.zoneInput = null;
  }

  connectedCallback() {
    if (!EZone.templateLoaded) {
      EZone.template = template;
      EZone.templateLoaded = true;
    }

    this._loadTemplate();
    this._fetchCitiesData();
  }

  _loadTemplate() {
    const template = EZone.template;

    if (template) {
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      this.citySelect = this.querySelector("#city-select");
      this.districtsSelect = this.querySelector("#location-select").closest(
        ".eselect-options-container"
      );
      this.zoneInput = this.querySelector("#zone");
    } else {
      console.error("Template not found!");
    }
  }

  _fetchCitiesData() {
    this.cities = getCity();
    if (this.cities) {
      this._populateCitySelect();
      this._addEventListeners();
      this._setAttributes();
    } else {
      console.error("Cities data is not available.");
    }
  }

  _populateCitySelect() {
    Object.keys(this.cities).forEach((city) => {
      const option = document.createElement("option");
      option.value = city;
      option.textContent = this.cities[city];
      this.citySelect.appendChild(option);
    });
  }

  _addEventListeners() {
    this.citySelect.addEventListener("change", this._onCityChange.bind(this));
    this.districtsSelect
      .querySelector("select")
      .addEventListener("change", this._onlocationChange.bind(this));
  }

  _setAttributes() {
    if (this.hasAttribute("value"))
      this.zoneInput.setAttribute("value", this.getAttribute("value"));

    if (this.hasAttribute("required")) {
      const required = this.getAttribute("required");
      if (required == 1 || required == 2) {
        this.citySelect.required = true;
      }
      if (required == 2) {
        this.districtsSelect.querySelector("select").required = true;
      }
    }
  }

  _onCityChange(event) {
    const selectedCity = event.target.value;
    if (selectedCity) {
      this._populatedistrictsSelect(selectedCity);

      this.zoneInput.value = JSON.stringify({
        city: getCityByName(selectedCity),
      });

      this.districtsSelect.style.display = "block";
    } else {
      this.districtsSelect.style.display = "none";
    }
  }

  _populatedistrictsSelect(city) {
    const locations = getDistricts(city);
    const locationSelect = this.districtsSelect.querySelector("select");
    locationSelect.innerHTML = '<option value="">تحديد المنطقة</option>';
    locations.forEach((location) => {
      const option = document.createElement("option");
      option.value = location.name;
      option.textContent = location.name;
      locationSelect.appendChild(option);
    });
  }

  _onlocationChange(event) {
    const selectedLocation = event.target.value;
    if (selectedLocation) {
      this._updateHiddenInputs(selectedLocation);
    }
  }

  _updateHiddenInputs(location) {
    const selectedCity = this.citySelect.value;
    const cityData = getDistrict(selectedCity, location);

    if (cityData) {
      cityData.city = getCityByName(selectedCity);
      this.zoneInput.value = JSON.stringify(cityData);
    }
  }
}
