// Access the cookie in JavaScript
window.getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

window.endpoint = "/api/";

window.token = decodeURIComponent(getCookie("default_license"));
window.csrf = decodeURIComponent(getCookie("csrf_token"));

window._encryptData = async (data) => {
  const enc = new TextEncoder();
  const keyBuffer = await crypto.subtle.digest(
    "SHA-256",
    enc.encode(window.csrf)
  ); // Hash the key to 256 bits

  const key = await window.crypto.subtle.importKey(
    "raw",
    keyBuffer,
    { name: "AES-GCM" },
    false,
    ["encrypt"]
  );

  const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Generate IV

  // Encrypt the data
  const encrypted = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    enc.encode(data)
  );

  // Encrypted data includes both the ciphertext and authentication tag (at the end)
  const encryptedArray = new Uint8Array(encrypted);

  // Convert encrypted data and IV to base64 for transport
  return {
    encryptedData: btoa(String.fromCharCode(...encryptedArray)), // Base64 encode encrypted data
    iv: btoa(String.fromCharCode(...iv)), // Base64 encode IV
  };
};

window._hashUrl = (url) => {
  const path = url.split("?");

  if (path[1]) {
    const urlParams = new URLSearchParams(path[1]);
    // Encrypt using a basic method (btoa - Base64 encoding)
    const encodedUrl = btoa(`${urlParams}:${window.csrf}`);
    return `${path[0]}?${encodedUrl}`;
  } else {
    return path[0];
  }
};

window._setParams = (url, obj) => {
  // Replace placeholders in the URL with actual values
  url = url.replace(/\{([^{}]*)\}/g, (match, key) => {
    return obj[key];
  });
  return url;
};

window._dateFormat = (d, format = "full") => {
  if (d) {
    const date = new Date(d);
    // Extract date components
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero based
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);

    // Construct the formatted date string
    if (format == "t") {
      return `${hours}:${minutes}`;
    } else if (format == "y") {
      return `${month}/${day}`;
    } else {
      return `${month}/${day}, ${hours}:${minutes}`;
    }
  } else {
    return "";
  }
};

window.changeLanguage = async (key) => {
  try {
    const config = {
      method: "GET",
      headers: {
        "X-CSRF-Token": window.csrf || "",
      },
    };

    const response = await fetch(`/api/switch/language/${key}`, config);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    const data = await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    location.reload();
  }
};

window.test = (data) => {
  return new Toast("TODO Default Message!", "success");
};

window.close_policy = (data) => {
  const backdrop = document.querySelector("#policy");
  const offcanvas = document.querySelector(".offcanvas-backdrop");

  if (backdrop && offcanvas) {
    backdrop.remove();
    offcanvas.remove();
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";

    return new Toast(
      "شكرا لك! لقد قبلت من خلال الخطوة السابقة لقبول شروط الخصوصية.",
      "success"
    );
  } else {
    return new Toast("TODO Default Message!", "danger");
  }
};

window.copy = (data) => {
  const el = document.createElement("textarea");
  el.value = data;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);

  return new Toast("تم النسخ!", "success");
};
