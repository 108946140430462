const serives = {
  others: "غير ذالك",
  health: "صحية",
  education: "تعليم",
  finance: "تجارة",
  transport: "نقل",
  food: "طعام",
  sport: "رياضة",
  event: "مناسبات",
  media: "الإعلام و تصميم",
  it: "تكنولوجيا",
  handcraft: "صناعة",
};

const jobs = {
  others: [
    "لدي خبرة في الصحة",
    "لدي خبرة في التعليم",
    "لدي خبرة في التجارة",
    "لدي خبرة في النقل",
    "لدي خبرة في الطعام",
    "لدي خبرة في الرياضة",
    "لدي خبرة في مناسبات",
    "لدي خبرة في الإعلام و تصميم",
    "لدي خبرة في تكنولوجيا",
    "لدي خبرة في صناعة",
    "لدي خبرة في إدارة الأعمال",
    "لدي خبرة في التسويق",
    "لدي خبرة في خدمة العملاء",
    "لدي خبرة في التحليل المالي",
    "لدي خبرة في الترجمة",
    "لدي خبرة في التصميم الجرافيكي",
    "لدي خبرة في الكتابة الصحفية",
    "لدي خبرة في العلاقات العامة",
  ],
  health: [
    "ممرض(ة) عام",
    "ممرض(ة) شخصي",
    "طبيب(ة) عام",
    "طبيب(ة) شخصي",
    "طبيب(ة) نسائية",
    "طبيب(ة) مستشفية",
    "طبيب(ة) اسنان",
    "طبيب(ة) عظمية",
    "طبيب(ة) نفسي",
    "طبيب(ة) مسالك بولية",
    "صيدلي(ة)",
    "طبيب(ة) جلدية",
    "أخصائي(ة) تغذية",
    "أخصائي(ة) عيون",
    "أخصائي(ة) فم وأسنان",
    "أخصائي(ة) جراحة تجميلية",
  ],
  education: [
    "مدرس(ة) عام",
    "مدرس(ة) ابتدائي",
    "مدرس(ة) اعدادي",
    "مدرس(ة) عربي",
    "مدرس(ة) رياضيات",
    "مدرس(ة) كمبيوتر",
    "مدرس(ة) هندسي",
    "مدرس(ة) انكليزي",
    "مدرس(ة) لفات",
    "مدرس(ة) تاريخ",
    "مدرس(ة) كيمياء",
    "مدرس(ة) فيزياء",
    "مدرس(ة) علم نفس",
    "مدرس(ة) فنون",
    "مدرس(ة) رياضة بدنية",
    "مدرس(ة) موسيقى",
    "أستاذ(ة) جامعي",
  ],
  finance: [
    "محاسب(ة)",
    "مستشار(ة) مالي",
    "أمين خزينة",
    "مدير(ة) مالي",
    "مدقق حسابات",
    "محلل(ة) مالي",
    "مستشار(ة) استثماري",
    "مدير(ة) حسابات",
    "خبير(ة) ضرائب",
  ],
  transport: [
    "سائق(ة) خاص",
    "سائق(ة) شاحنة",
    "سائق(ة) حافلة",
    "سائق(ة) تكسي",
    "مدير(ة) نقل",
    "مساعد(ة) سائق",
    "سائق(ة) توصيل",
  ],
  food: [
    "طباخ(ة)",
    "شيف(ة)",
    "نادل(ة)",
    "غسّال(ة) صحون",
    "عامل(ة) في المطعم",
    "مدير(ة) مطعم",
    "مساعد(ة) طباخ",
    "موظف(ة) في محل حلويات",
    "عامل(ة) في صناعة الغذاء",
  ],
  sport: [
    "مدرب(ة) رياضي",
    "لاعب(ة) محترف",
    "مساعد(ة) مدرب",
    "أخصائي(ة) رياضي",
    "حكم(ة)",
    "منظم(ة) رياضي",
    "مدير(ة) نشاط رياضي",
  ],
  event: [
    "منظم(ة) مناسبات",
    "منسق(ة) فعاليات",
    "مدير(ة) مؤتمر",
    "منظم(ة) حفلات",
    "منسق(ة) مهرجانات",
    "منسق(ة) معارض",
    "منظم(ة) حفلات زفاف",
    "منظم(ة) فعاليات تجارية",
  ],
  media: [
    "صحفي(ة)",
    "مصور(ة)",
    "مراسل(ة)",
    "محرر(ة) صحفي",
    "مخرج(ة) تلفزيوني",
    "مخرج(ة) سينمائي",
    "كاتب(ة)",
    "مصمم(ة) جرافيك",
    "مذيع(ة)",
    "منتج(ة)",
  ],
  it: [
    "مطور(ة) ويب",
    "مطور(ة) تطبيقات",
    "مستشار(ة) تكنولوجيا",
    "مهندس(ة) برمجيات",
    "محلل(ة) بيانات",
    "مدير(ة) تكنولوجيا المعلومات",
    "أخصائي(ة) دعم فني",
    "مهندس(ة) شبكات",
    "مبرمج(ة)",
    "مدير(ة) مشروع تكنولوجيا",
  ],
  handcraft: [
    "نجار(ة)",
    "حداد(ة)",
    "خياط(ة)",
    "موزع(ة) الحرف اليدوية",
    "صانع(ة) ألبسة",
    "صانع(ة) مجوهرات",
    "فنان(ة) نحت",
    "صانع(ة) خزف",
    "صانع(ة) أدوات منزلية",
    "فنان(ة) تصميم داخلي",
  ],
};

export function getServices() {
  return serives;
}

export function getJobs(serive) {
  return jobs[serive];
}
