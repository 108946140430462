const cities = {
  damascus: "دمشق",
  aleppo: "حلب",
  homs: "حمص",
  hama: "حماة",
  latakia: "اللاذقية",
  tartus: "طرطوس",
  idlib: "إدلب",
  deirEzzor: "دير الزور",
  raqqa: "الرقة",
  al_hasakah: "حسكة",
  quneitra: "قنيطرة",
  qamishli: "قامشلي",
  daraa: "درعا",
  suweida: "سويداء",
  other: "غير ذلك",
};

const districts = {
    damascus: [
    {
      name: "مركز المدينة",
      lat: 33.5131,
      lng: 36.2919,
    },
    {
      name: "الشاغور",
      lat: 33.51,
      lng: 36.2845,
    },
    {
      name: "الميدان",
      lat: 33.493,
      lng: 36.292,
    },
    {
      name: "ركن الدين",
      lat: 33.5275,
      lng: 36.3125,
    },
    {
      name: "باب توما",
      lat: 33.5135,
      lng: 36.303,
    },
    {
      name: "المزة",
      lat: 33.512,
      lng: 36.289,
    },
    {
      name: "دوما",
      lat: 33.553,
      lng: 36.466,
    },
    {
      name: "الغوطة الشرقية",
      lat: 33.541,
      lng: 36.423,
    },
    {
      name: "الغوطة الغربية",
      lat: 33.495,
      lng: 36.179,
    },
    {
      name: "زبداني",
      lat: 33.599,
      lng: 36.257,
    },
    {
      name: "جديدة عرطوز",
      lat: 33.51,
      lng: 36.2525,
    },
    {
      name: "قدسيا",
      lat: 33.5345,
      lng: 36.2595,
    },
    {
      name: "التل",
      lat: 33.5975,
      lng: 36.3575,
    },
    {
      name: "قطنا",
      lat: 33.6235,
      lng: 36.247,
    },
    {
      name: "صحنايا",
      lat: 33.5425,
      lng: 36.296,
    },
    {
      name: "المزرعة",
      lat: 33.515,
      lng: 36.291,
    },
    {
      name: "التجارة",
      lat: 33.5115,
      lng: 36.2925,
    },
    {
      name: "العباسيين",
      lat: 33.507,
      lng: 36.2885,
    },
    {
      name: "القابون",
      lat: 33.5765,
      lng: 36.3145,
    },
    {
      name: "الزاهرة",
      lat: 33.4935,
      lng: 36.297,
    },
    {
      name: "الشعلان",
      lat: 33.511,
      lng: 36.279,
    },
    {
      name: "المزة 86",
      lat: 33.505,
      lng: 36.289,
    },
    {
      name: "أبو رمانة",
      lat: 33.5125,
      lng: 36.2955,
    },
    {
      name: "باب شرقي",
      lat: 33.512,
      lng: 36.29,
    },
    {
      name: "الفحامة",
      lat: 33.503,
      lng: 36.29,
    },
    {
      name: "القصور",
      lat: 33.509,
      lng: 36.293,
    },
    {
      name: "حران العواميد",
      lat: 33.475,
      lng: 36.263,
    },
    {
      name: "سقبا",
      lat: 33.5115,
      lng: 36.395,
    },
  ],

  aleppo: [
    {
      name: "مركز المدينة",
      lat: 36.2028,
      lng: 37.1343,
    },
    {
      name: "الشيخ مقصود",
      lat: 36.2425,
      lng: 37.1395,
    },
    {
      name: "المعصرانية",
      lat: 36.23,
      lng: 37.1305,
    },
    {
      name: "سليمان الحلبي",
      lat: 36.233,
      lng: 37.134,
    },
    {
      name: "الفردوس",
      lat: 36.2205,
      lng: 37.139,
    },
    {
      name: "الموكامبو",
      lat: 36.215,
      lng: 37.135,
    },
    {
      name: "الأشرفية",
      lat: 36.2435,
      lng: 37.134,
    },
    {
      name: "الزبدية",
      lat: 36.2235,
      lng: 37.149,
    },
    {
      name: "الأنصاري",
      lat: 36.2155,
      lng: 37.1435,
    },
    {
      name: "باب الحديد",
      lat: 36.2295,
      lng: 37.1455,
    },
    {
      name: "الليرمون",
      lat: 36.2295,
      lng: 37.153,
    },
    {
      name: "باب النيرب",
      lat: 36.214,
      lng: 37.178,
    },
    {
      name: "الحمدانية",
      lat: 36.192,
      lng: 37.1505,
    },
    {
      name: "حريتان",
      lat: 36.2615,
      lng: 37.124,
    },
    {
      name: "عندان",
      lat: 36.276,
      lng: 37.1275,
    },
    {
      name: "عفرين",
      lat: 36.5255,
      lng: 37.1055,
    },
    {
      name: "الفرافرة",
      lat: 36.2165,
      lng: 37.128,
    },
    {
      name: "السكري",
      lat: 36.2145,
      lng: 37.1325,
    },
    {
      name: "السليمانية",
      lat: 36.2085,
      lng: 37.127,
    },
    {
      name: "المحلق",
      lat: 36.2095,
      lng: 37.1335,
    },
    {
      name: "القاطرجي",
      lat: 36.208,
      lng: 37.139,
    },
    {
      name: "بستان الباشا",
      lat: 36.2155,
      lng: 37.1425,
    },
    {
      name: "جسر الشغور",
      lat: 36.269,
      lng: 37.0555,
    },
    {
      name: "باب التبانة",
      lat: 36.2155,
      lng: 37.1505,
    },
    {
      name: "قاضي عسكر",
      lat: 36.2365,
      lng: 37.144,
    },
    {
      name: "الجميلية",
      lat: 36.2115,
      lng: 37.146,
    },
    {
      name: "العزيزية",
      lat: 36.1945,
      lng: 37.154,
    },
  ],

  homs: [
    {
      name: "مركز المدينة",
      lat: 34.7325,
      lng: 36.718,
    },
    {
      name: "الزهراء",
      lat: 34.7485,
      lng: 36.729,
    },
    {
      name: "القصور",
      lat: 34.7295,
      lng: 36.7355,
    },
    {
      name: "باب تدمر",
      lat: 34.727,
      lng: 36.7235,
    },
    {
      name: "باب السباع",
      lat: 34.7415,
      lng: 36.72,
    },
    {
      name: "الرفاعي",
      lat: 34.7375,
      lng: 36.7325,
    },
    {
      name: "الكرم",
      lat: 34.732,
      lng: 36.735,
    },
    {
      name: "الخالدية",
      lat: 34.744,
      lng: 36.724,
    },
    {
      name: "وادي الذهب",
      lat: 34.736,
      lng: 36.731,
    },
    {
      name: "المحطة",
      lat: 34.7405,
      lng: 36.7335,
    },
    {
      name: "الغجر",
      lat: 34.739,
      lng: 36.709,
    },
    {
      name: "تدمر",
      lat: 34.4585,
      lng: 38.2335,
    },
    {
      name: "الحمرا",
      lat: 34.741,
      lng: 36.7155,
    },
    {
      name: "المنطقة الصناعية",
      lat: 34.745,
      lng: 36.714,
    },
    {
      name: "النزهة",
      lat: 34.7465,
      lng: 36.7105,
    },
    {
      name: "المهاجرين",
      lat: 34.7505,
      lng: 36.719,
    },
    {
      name: "أحياء بابا عمرو",
      lat: 34.772,
      lng: 36.758,
    },
    {
      name: "المدينة الجامعية",
      lat: 34.746,
      lng: 36.7025,
    },
    {
      name: "حارة التربة",
      lat: 34.7475,
      lng: 36.7095,
    },
    {
      name: "السبع بحرات",
      lat: 34.73,
      lng: 36.736,
    },
    {
      name: "عكرمة",
      lat: 34.7235,
      lng: 36.7425,
    },
    {
      name: "تلكلخ",
      lat: 34.5415,
      lng: 36.7555,
    },
  ],

  hama: [
    {
      name: "مركز المدينة",
      lat: 35.131,
      lng: 36.7465,
    },
    {
      name: "القصور",
      lat: 35.1325,
      lng: 36.746,
    },
    {
      name: "العدوية",
      lat: 35.127,
      lng: 36.7445,
    },
    {
      name: "الحميدية",
      lat: 35.1275,
      lng: 36.751,
    },
    {
      name: "الشيخ عنبر",
      lat: 35.1305,
      lng: 36.749,
    },
    {
      name: "الزور",
      lat: 35.1215,
      lng: 36.7385,
    },
    {
      name: "الجبيلة",
      lat: 35.12,
      lng: 36.7355,
    },
    {
      name: "المطار",
      lat: 35.136,
      lng: 36.755,
    },
    {
      name: "كفربهم",
      lat: 35.099,
      lng: 36.6675,
    },
    {
      name: "النواعير",
      lat: 35.1335,
      lng: 36.7495,
    },
    {
      name: "محردة",
      lat: 35.2575,
      lng: 36.6075,
    },
    {
      name: "باب قبلي",
      lat: 35.1285,
      lng: 36.747,
    },
    {
      name: "السقيلبية",
      lat: 35.154,
      lng: 36.6645,
    },
    {
      name: "القلعة",
      lat: 35.13,
      lng: 36.7455,
    },
    {
      name: "الفراية",
      lat: 35.122,
      lng: 36.733,
    },
    {
      name: "القطم",
      lat: 35.1295,
      lng: 36.7485,
    },
    {
      name: "الميدان",
      lat: 35.126,
      lng: 36.748,
    },
    {
      name: "الشيخ علي",
      lat: 35.0895,
      lng: 36.6165,
    },
    {
      name: "المنصور",
      lat: 35.1315,
      lng: 36.744,
    },
    {
      name: "العدوية",
      lat: 35.1215,
      lng: 36.745,
    },
    {
      name: "التمانعة",
      lat: 35.1355,
      lng: 36.685,
    },
  ],

  latakia: [
    {
      name: "مركز المدينة",
      lat: 35.533,
      lng: 35.783,
    },
    {
      name: "المدينة الرياضية",
      lat: 35.539,
      lng: 35.777,
    },
    {
      name: "الزراعة",
      lat: 35.5395,
      lng: 35.7895,
    },
    {
      name: "الكرنك",
      lat: 35.5335,
      lng: 35.7865,
    },
    {
      name: "طريق الشاطئ",
      lat: 35.531,
      lng: 35.7795,
    },
    {
      name: "الميدان",
      lat: 35.5285,
      lng: 35.784,
    },
    {
      name: "الشيخ ضاهر",
      lat: 35.534,
      lng: 35.788,
    },
    {
      name: "الدعتور",
      lat: 35.5305,
      lng: 35.7845,
    },
    {
      name: "كسب",
      lat: 35.9865,
      lng: 36.3845,
    },
    {
      name: "جبلة",
      lat: 35.3345,
      lng: 35.5755,
    },
    {
      name: "النزهة",
      lat: 35.5295,
      lng: 35.7825,
    },
    {
      name: "القرداحة",
      lat: 35.5,
      lng: 35.853,
    },
    {
      name: "الرمل الجنوبي",
      lat: 35.5365,
      lng: 35.776,
    },
    {
      name: "الرمل الشمالي",
      lat: 35.536,
      lng: 35.783,
    },
    {
      name: "بانياس",
      lat: 35.1265,
      lng: 35.1585,
    },
    {
      name: "الضاحية الجنوبية",
      lat: 35.536,
      lng: 35.7805,
    },
    {
      name: "سكنة الجليل",
      lat: 35.5375,
      lng: 35.785,
    },
    {
      name: "المنطقة الصناعية",
      lat: 35.5225,
      lng: 35.7925,
    },
  ],

  tartus: [
    {
      name: "مركز المدينة",
      lat: 34.883,
      lng: 35.881,
    },
    {
      name: "المنطقة الصناعية",
      lat: 34.8855,
      lng: 35.884,
    },
    {
      name: "الغربي",
      lat: 34.8865,
      lng: 35.8825,
    },
    {
      name: "كرم الزيتون",
      lat: 34.884,
      lng: 35.8765,
    },
    {
      name: "الشيخ ضاهر",
      lat: 34.887,
      lng: 35.88,
    },
    {
      name: "الحمدانية",
      lat: 34.89,
      lng: 35.8795,
    },
    {
      name: "الطريق العام",
      lat: 34.885,
      lng: 35.8785,
    },
    {
      name: "القصور",
      lat: 34.8835,
      lng: 35.883,
    },
    {
      name: "الكرنك",
      lat: 34.886,
      lng: 35.875,
    },
    {
      name: "بانياس",
      lat: 35.1265,
      lng: 35.1585,
    },
    {
      name: "الدريكيش",
      lat: 34.936,
      lng: 35.876,
    },
    {
      name: "القدموس",
      lat: 34.9265,
      lng: 36.0225,
    },
    {
      name: "البلدية",
      lat: 34.888,
      lng: 35.877,
    },
    {
      name: "الضاحية",
      lat: 34.8895,
      lng: 35.8745,
    },
    {
      name: "جسر القاطرجي",
      lat: 34.883,
      lng: 35.87,
    },
    {
      name: "الزهور",
      lat: 34.8855,
      lng: 35.8705,
    },
    {
      name: "الكورنيش",
      lat: 34.8875,
      lng: 35.875,
    },
    {
      name: "الصفصافة",
      lat: 34.959,
      lng: 35.888,
    },
    {
      name: "الريحانية",
      lat: 34.9065,
      lng: 35.8775,
    },
    {
      name: "مار تقلا",
      lat: 34.889,
      lng: 35.882,
    },
  ],

  idlib: [
    {
      name: "مركز المدينة",
      lat: 35.883,
      lng: 36.833,
    },
    {
      name: "الالغربي",
      lat: 35.884,
      lng: 36.835,
    },
    {
      name: "الالشرقي",
      lat: 35.882,
      lng: 36.836,
    },
    {
      name: "الشهداء",
      lat: 35.885,
      lng: 36.8345,
    },
    {
      name: "المنطقة الصناعية",
      lat: 35.8875,
      lng: 36.832,
    },
    {
      name: "معرة النعمان",
      lat: 35.7265,
      lng: 36.7365,
    },
    {
      name: "سراقب",
      lat: 35.892,
      lng: 36.868,
    },
    {
      name: "جسر الشغور",
      lat: 35.836,
      lng: 36.53,
    },
    {
      name: "خان شيخون",
      lat: 35.7015,
      lng: 36.641,
    },
    {
      name: "بنش",
      lat: 35.87,
      lng: 36.8715,
    },
    {
      name: "أريحا",
      lat: 35.8145,
      lng: 36.6465,
    },
    {
      name: "طيبة الإمام",
      lat: 35.7565,
      lng: 36.726,
    },
    {
      name: "التمانعة",
      lat: 35.699,
      lng: 36.7535,
    },
    {
      name: "الضاحية الشمالية",
      lat: 35.8855,
      lng: 36.8335,
    },
    {
      name: "الضاحية الجنوبية",
      lat: 35.8815,
      lng: 36.837,
    },
    {
      name: "السيدة زينب",
      lat: 35.876,
      lng: 36.8325,
    },
    {
      name: "الجسر",
      lat: 35.8825,
      lng: 36.8355,
    },
  ],

  deirEzzor: [
    {
      name: "مركز المدينة",
      lat: 35.3333,
      lng: 40.15,
    },
    {
      name: "الجورة",
      lat: 35.338,
      lng: 40.159,
    },
    {
      name: "القصور",
      lat: 35.34,
      lng: 40.157,
    },
    {
      name: "الحميدية",
      lat: 35.344,
      lng: 40.165,
    },
    {
      name: "الشحيل",
      lat: 35.323,
      lng: 40.16,
    },
    {
      name: "الكنامات",
      lat: 35.335,
      lng: 40.17,
    },
    {
      name: "الجبيلة",
      lat: 35.33,
      lng: 40.18,
    },
    {
      name: "المطار",
      lat: 35.352,
      lng: 40.155,
    },
    {
      name: "العشارة",
      lat: 35.38,
      lng: 40.28,
    },
    {
      name: "ريف دير الزور الشرقي",
      lat: 35.4,
      lng: 40.25,
    },
    {
      name: "ريف دير الزور الغربي",
      lat: 35.26,
      lng: 40.1,
    },
    {
      name: "البصيرة",
      lat: 35.486,
      lng: 40.257,
    },
    {
      name: "موحسن",
      lat: 35.39,
      lng: 40.238,
    },
    {
      name: "الميادين",
      lat: 35.46,
      lng: 40.274,
    },
    {
      name: "الصالحية",
      lat: 35.52,
      lng: 40.33,
    },
    {
      name: "الشامية",
      lat: 35.5315,
      lng: 40.265,
    },
    {
      name: "التبني",
      lat: 35.42,
      lng: 40.22,
    },
  ],

  raqqa: [
    {
      name: "مركز المدينة",
      lat: 35.9624,
      lng: 39.0208,
    },
    {
      name: "الرقة القديمة",
      lat: 35.965,
      lng: 39.025,
    },
    {
      name: "الفردوس",
      lat: 35.9675,
      lng: 39.03,
    },
    {
      name: "المشلب",
      lat: 35.975,
      lng: 39.035,
    },
    {
      name: "التوسعية",
      lat: 35.96,
      lng: 39.015,
    },
    {
      name: "القادسية",
      lat: 35.955,
      lng: 39.01,
    },
    {
      name: "السلمية",
      lat: 35.9605,
      lng: 39.0205,
    },
    {
      name: "ريف الرقة الشرقي",
      lat: 36.0,
      lng: 39.1,
    },
    {
      name: "ريف الرقة الغربي",
      lat: 35.91,
      lng: 38.96,
    },
    {
      name: "المنصورة",
      lat: 35.9535,
      lng: 39.1325,
    },
    {
      name: "الكرامة",
      lat: 35.93,
      lng: 39.11,
    },
    {
      name: "الطبقة",
      lat: 35.755,
      lng: 38.979,
    },
    {
      name: "عين عيسى",
      lat: 36.002,
      lng: 38.8735,
    },
    {
      name: "الكشكية",
      lat: 35.91,
      lng: 39.05,
    },
  ],

  al_hasakah: [
    {
      name: "مركز المدينة",
      lat: 36.7467,
      lng: 40.768,
    },
    {
      name: "النشوة",
      lat: 36.75,
      lng: 40.775,
    },
    {
      name: "المساكن",
      lat: 36.745,
      lng: 40.77,
    },
    {
      name: "تل حجر",
      lat: 36.7455,
      lng: 40.765,
    },
    {
      name: "الهلالية",
      lat: 36.7505,
      lng: 40.78,
    },
    {
      name: "الناصرة",
      lat: 36.742,
      lng: 40.762,
    },
    {
      name: "العزيزية",
      lat: 36.7415,
      lng: 40.7685,
    },
    {
      name: "ريف الحسكة الجنوبي",
      lat: 36.65,
      lng: 40.75,
    },
    {
      name: "ريف الحسكة الشمالي",
      lat: 36.85,
      lng: 40.85,
    },
    {
      name: "الشدادي",
      lat: 36.528,
      lng: 40.722,
    },
    {
      name: "تل براك",
      lat: 36.723,
      lng: 40.999,
    },
    {
      name: "راس العين",
      lat: 36.579,
      lng: 40.976,
    },
    {
      name: "تل حميس",
      lat: 36.59,
      lng: 40.929,
    },
    {
      name: "القحطانية",
      lat: 36.704,
      lng: 40.7465,
    },
  ],

  quneitra: [
    {
      name: "مركز المدينة",
      lat: 33.0667,
      lng: 35.7944,
    },
    {
      name: "البعث",
      lat: 33.071,
      lng: 35.799,
    },
    {
      name: "الزهور",
      lat: 33.07,
      lng: 35.7925,
    },
    {
      name: "الأندلس",
      lat: 33.065,
      lng: 35.8,
    },
    {
      name: "خان أرنبة",
      lat: 33.09,
      lng: 35.75,
    },
    {
      name: "جباثا الخشب",
      lat: 33.14,
      lng: 35.85,
    },
    {
      name: "نبع الفوار",
      lat: 33.129,
      lng: 35.825,
    },
    {
      name: "ريف القنيطرة الشمالي",
      lat: 33.12,
      lng: 35.87,
    },
    {
      name: "ريف القنيطرة الجنوبي",
      lat: 33.04,
      lng: 35.73,
    },
    {
      name: "مسحرة",
      lat: 33.042,
      lng: 35.778,
    },
    {
      name: "جباتا الخشب",
      lat: 33.14,
      lng: 35.85,
    },
  ],

  qamishli: [
    {
      name: "مركز المدينة",
      lat: 37.0314,
      lng: 41.2269,
    },
    {
      name: "النعيم",
      lat: 37.036,
      lng: 41.228,
    },
    {
      name: "طي",
      lat: 37.03,
      lng: 41.22,
    },
    {
      name: "الهلالية",
      lat: 37.0275,
      lng: 41.2335,
    },
    {
      name: "الغربية",
      lat: 37.025,
      lng: 41.235,
    },
    {
      name: "الكورنيش",
      lat: 37.029,
      lng: 41.23,
    },
    {
      name: "الفردوس",
      lat: 37.0225,
      lng: 41.24,
    },
    {
      name: "ريف القامشلي الجنوبي",
      lat: 36.95,
      lng: 41.17,
    },
    {
      name: "ريف القامشلي الشمالي",
      lat: 37.1,
      lng: 41.29,
    },
    {
      name: "اليعربية",
      lat: 37.0475,
      lng: 41.08,
    },
    {
      name: "تل حميس",
      lat: 36.59,
      lng: 40.929,
    },
    {
      name: "رميلان",
      lat: 36.725,
      lng: 41.236,
    },
    {
      name: "الدرباسية",
      lat: 37.0545,
      lng: 41.04,
    },
  ],

  daraa: [
    {
      name: "مركز المدينة",
      lat: 32.6167,
      lng: 36.1667,
    },
    {
      name: "السبيل",
      lat: 32.62,
      lng: 36.17,
    },
    {
      name: "الشهداء",
      lat: 32.625,
      lng: 36.16,
    },
    {
      name: "القصور",
      lat: 32.618,
      lng: 36.175,
    },
    {
      name: "المحطة",
      lat: 32.612,
      lng: 36.162,
    },
    {
      name: "الكاشف",
      lat: 32.6135,
      lng: 36.1575,
    },
    {
      name: "نوى",
      lat: 32.724,
      lng: 36.264,
    },
    {
      name: "طفس",
      lat: 32.6535,
      lng: 36.222,
    },
    {
      name: "الصنمين",
      lat: 32.71,
      lng: 36.161,
    },
    {
      name: "جاسم",
      lat: 32.7025,
      lng: 36.062,
    },
    {
      name: "المزيريب",
      lat: 32.684,
      lng: 36.182,
    },
    {
      name: "الشيخ مسكين",
      lat: 32.6765,
      lng: 36.131,
    },
    {
      name: "ريف درعا الشرقي",
      lat: 32.59,
      lng: 36.12,
    },
    {
      name: "ريف درعا الغربي",
      lat: 32.71,
      lng: 36.25,
    },
  ],

  suweida: [
    {
      name: "مركز المدينة",
      lat: 32.708,
      lng: 36.694,
    },
    {
      name: "البحار",
      lat: 32.7115,
      lng: 36.695,
    },
    {
      name: "الشهباء",
      lat: 32.715,
      lng: 36.698,
    },
    {
      name: "الجبل",
      lat: 32.72,
      lng: 36.7,
    },
    {
      name: "الروماني",
      lat: 32.705,
      lng: 36.69,
    },
    {
      name: "الطي",
      lat: 32.701,
      lng: 36.705,
    },
    {
      name: "شهبا",
      lat: 32.685,
      lng: 36.642,
    },
    {
      name: "صلخد",
      lat: 32.7,
      lng: 36.558,
    },
    {
      name: "كناكر",
      lat: 32.67,
      lng: 36.7,
    },
    {
      name: "القريا",
      lat: 32.683,
      lng: 36.705,
    },
    {
      name: "المليحة الغربية",
      lat: 32.666,
      lng: 36.668,
    },
    {
      name: "البثينة",
      lat: 32.633,
      lng: 36.712,
    },
    {
      name: "ريف السويداء الشرقي",
      lat: 32.62,
      lng: 36.74,
    },
    {
      name: "ريف السويداء الغربي",
      lat: 32.68,
      lng: 36.67,
    },
  ],
};

export function getDistrict(city, district) {
  return districts[city].find((dist) => dist.name === district);
}

export function getCity() {
  return cities;
}

export function getCityByName(city) {
  return cities[city];
}

export function getDistricts(city) {
  return districts[city] || [];
}
