// HTML template for the custom element
const templateHTML = `
  <div class="eselect-options-container mb-3">
    <label class="select-label text-capitalize">الخدمة</label>
    <select id="services-select" class="select-dropdown form-select" aria-label="Default select example">
      <option value="">تحديد الخدمة</option>
    </select>
    <span class="hint-field form-text"></span>
    <span class="error-message"></span>
  </div>
  <div class="eselect-options-container mb-3" style="display: none;">
    <label class="select-label text-capitalize">الوظيفة</label>
    <select id="job-select" class="select-dropdown form-select" aria-label="Default select example">
      <option value="">تحديد الوظيفة</option>
    </select>
    <span class="hint-field form-text"></span>
    <span class="error-message"></span>
  </div>
  <input type="hidden" id="service" name="service">
`;

// Create a template element to store the structure
const template = document.createElement("template");
template.innerHTML = templateHTML;

import { getServices, getJobs } from "./services.js";

export default class ESerives extends HTMLElement {
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    this.serives = null;
    this.servicesSelect = null;
    this.jobSelect = null;
    this.serviceInput = null;
  }

  connectedCallback() {
    if (!ESerives.templateLoaded) {
      ESerives.template = template;
      ESerives.templateLoaded = true;
    }

    this._loadTemplate();
    this._fetchSerivesData();
    this._setAttributes();
  }

  _loadTemplate() {
    const template = ESerives.template;

    if (template) {
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      this.servicesSelect = this.querySelector("#services-select");
      this.jobSelect = this.querySelector("#job-select").closest(
        ".eselect-options-container"
      );
      this.serviceInput = this.querySelector("#service");
    } else {
      console.error("Template not found!");
    }
  }

  _fetchSerivesData() {
    this.serives = getServices();
    if (this.serives) {
      this._populateservicesSelect();
      this._addEventListeners();
    } else {
      console.error("Serives data is not available.");
    }
  }

  _setAttributes() {
    if (this.hasAttribute("value"))
      this.serviceInput.setAttribute("value", this.getAttribute("value"));

    if (this.hasAttribute("required")) {
      const required = this.getAttribute("required");
      if (required == 1 || required == 2) {
        this.servicesSelect.required = true;
      }
      if (required == 2) {
        this.jobSelect.required = true;
      }
    }
  }

  _populateservicesSelect() {
    Object.keys(this.serives).forEach((services) => {
      const option = document.createElement("option");
      option.value = services;
      option.textContent = this.serives[services];
      this.servicesSelect.appendChild(option);
    });
  }

  _addEventListeners() {
    this.servicesSelect.addEventListener(
      "change",
      this._onServicesChange.bind(this)
    );
    this.jobSelect
      .querySelector("select")
      .addEventListener("change", this._onJobChange.bind(this));
  }

  _onServicesChange(event) {
    const selectedservices = event.target.value;
    if (selectedservices) {
      this._populatejobSelect(selectedservices);

      this.serviceInput.value = JSON.stringify({
        service: this.serives[selectedservices],
      });

      this.jobSelect.style.display = "block";
    } else {
      this.jobSelect.style.display = "none";
    }
  }

  _populatejobSelect(services) {
    const jobs = getJobs(services);
    const jobSelect = this.jobSelect.querySelector("select");
    jobSelect.innerHTML = '<option value="">تحديد الوظيفة</option>';
    jobs.forEach((job) => {
      const option = document.createElement("option");
      option.value = job;
      option.textContent = job;
      jobSelect.appendChild(option);
    });
  }

  _onJobChange(event) {
    const selectedjob = event.target.value;
    if (selectedjob) {
      this._updateHiddenInputs(selectedjob);
    }
  }

  _updateHiddenInputs(job) {
    const selectedservices = this.servicesSelect.value;

    this.serviceInput.value = JSON.stringify({
      job: job,
      service: this.serives[selectedservices],
    });
  }
}
