import { EToast } from "./elements/toast.js";
customElements.define("e-toast", EToast);

// Define the custom element
import EImage from "./elements/image.js";
customElements.define("e-image", EImage);

import SubmitButton from "./elements/submit-button.js";
customElements.define("e-submit-button", SubmitButton);

import OverviewTable from "./elements/overview.js";
customElements.define("overview-table", OverviewTable);

import EZone from "./talbya/zone/selector.js";
customElements.define("e-zone", EZone);

import ESerives from "./talbya/jobs/selector.js";
customElements.define("e-serives", ESerives);

import ETalbyaCard from "./talbya/cards/index.js";
customElements.define("e-talbya-cards", ETalbyaCard);
