// HTML template for the EToast component
const templateHTML = `<div dir="ltr" class="toast-pop toast show align-items-center border-0" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="d-flex">
    <div class="toast-message toast-body">
      Hello, world! This is a toast message.
    </div>
    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
</div>`;

// Create a template element to store the card structure
const template = document.createElement("template");
template.id = "e-toast-template";
template.innerHTML = templateHTML;

export class EToast extends HTMLElement {
  // Define static properties for template handling
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    // Initialize class properties
    this.toast = null;
    this.toastMessage = null;
  }

  // Called when the element is added to the DOM
  connectedCallback() {
    // Load the template if it's not already loaded
    if (!EToast.templateLoaded) {
      EToast.template = template;
      EToast.templateLoaded = true;
    }

    this._loadTemplate();
    this._setAttributes();
    this._dataset();
  }

  // Method to load the template into the element
  _loadTemplate() {
    const template = EToast.template;

    if (template) {
      // Clone the template content and attach it to the custom element
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      // Store references to key elements within the template
      this.toast = this.querySelector(".toast-pop");
      this.toastMessage = this.querySelector(".toast-message");
    } else {
      console.error("Template not found!"); // Error handling if the template is missing
    }
  }

  // Method to set attributes based on the element's attributes
  _setAttributes() {
    // Ensure the card container is uniquely identifiable
    this.toast.setAttribute("role", "toast-pop-" + this._generateUniqueId());

    // Set attributes related to accessibility and identification
    this.toastMessage.setAttribute(
      "role",
      "toast-message-" + this._generateUniqueId()
    );

    this.toast.title =
      this.getAttribute("title") || "toast description not available";

    const ariaLabel = this.getAttribute("aria-label") || this.toast.title;
    this.toast.setAttribute("aria-label", ariaLabel);

    const color = this.getAttribute("color") || "info";
    this.toast.classList.add(`text-bg-${color}`);

    this.toast.id =
      this.getAttribute("id") || "e-toast-" + this._generateUniqueId();
    this.toast.setAttribute("role", "toast-" + this._generateUniqueId());
    this.toast.setAttribute("tabindex", "0"); // Make the card focusable

    const duration = this.getAttribute("duration") || false;

    if (duration) {
      setTimeout(() => {
        this.toast.remove();
      }, duration);
    }
  }

  // Helper method to generate a unique identifier
  _generateUniqueId() {
    return Math.floor(Math.random() * 100000);
  }

  _dataset() {
    // Set the message text
    this.toastMessage.textContent =
      this.getAttribute("message") || "no Message";
  }
}

export class Toast {
  constructor(message, color = "info", duration = 3000) {
    this.message = message;
    // Automatically create a toast notification when an error is thrown
    this._showToast(message, color, duration);
  }

  /**
   * Display a toast notification for the error
   * @param {string} message - The message to display in the toast
   * @param {string} color - The color of the toast (e.g., "error", "success")
   */
  _showToast(message, color, duration) {
    const toast = new EToast();
    toast.setAttribute("message", message);
    toast.setAttribute("color", color);
    toast.setAttribute("duration", duration);

    const container = document.getElementById("notification-message");
    container.classList.remove("d-none");

    if (container) {
      container.appendChild(toast);
      setTimeout(() => {
        this._removeToast(container, toast);
      }, duration);

      toast.querySelector(".btn-close").onclick = (event) =>
        this._removeToast(container, toast);
    } else {
      console.warn("Notification container not found!");
    }
  }

  _removeToast(container, toast) {
    toast.remove();
    if (container.children.length == 0) container.classList.add("d-none");
  }
}

// Attach to window to make it globally accessible
window.Toast = Toast;
