document.addEventListener("DOMContentLoaded", () => {
  "use strict";

  // Initialize and resize images
  initializeImages();
  resizeImages();

  // Lazy load custom functions from elements with `jsCallback` class
  document.querySelectorAll(".jsCallback").forEach((div) => {
    const [functionName, ...functionArgs] = div
      .getAttribute("data-function")
      .split(", ");
    if (functionName && typeof window[functionName] === "function") {
      window[functionName](...functionArgs);
    } else {
      console.error(`Function ${functionName} is not defined or is invalid.`);
    }
  });

  // Set theme based on local storage
  const htmlElement = document.documentElement;
  const currentTheme = localStorage.getItem("bsTheme") || "dark";
  htmlElement.setAttribute("data-bs-theme", currentTheme);
  document.body.classList.add(`${currentTheme}-mode`);

  const switchElement = document.getElementById("darkModeSwitch");
  if (switchElement) {
    switchElement.checked = currentTheme === "dark";
    switchElement.addEventListener("change", () => {
      const newTheme = switchElement.checked ? "dark" : "light";
      htmlElement.setAttribute("data-bs-theme", newTheme);
      document.body.classList.toggle("dark-mode", newTheme === "dark");
      document.body.classList.toggle("light-mode", newTheme === "light");
      localStorage.setItem("bsTheme", newTheme);
    });
  }

  // Toggle 'scrolled' class based on scroll position
  const toggleScrolled = () => {
    document.body.classList.toggle("scrolled", window.scrollY > 100);
  };
  document.addEventListener("scroll", toggleScrolled);
  window.addEventListener("load", toggleScrolled);

  // Scroll to top button logic
  const scrollTop = document.querySelector(".scroll-top");
  if (scrollTop) {
    const toggleScrollTop = () => {
      scrollTop.classList.toggle("active", window.scrollY > 100);
      scrollTop.classList.toggle("d-none", window.scrollY <= 100);
    };
    scrollTop.addEventListener("click", (e) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
    window.addEventListener("load", toggleScrollTop);
    document.addEventListener("scroll", toggleScrollTop);
  }

  // Smooth scroll adjustment for hash links on page load
  window.addEventListener("load", () => {
    if (window.location.hash) {
      const section = document.querySelector(window.location.hash);
      if (section) {
        setTimeout(() => {
          const offset =
            parseInt(getComputedStyle(section).scrollMarginTop, 10) || 0;
          window.scrollTo({
            top: section.offsetTop - offset,
            behavior: "smooth",
          });
        }, 100);
      }
    }
  });

  // Preloader removal logic
  const preloader = document.getElementById("preloader");
  if (preloader) {
    window.addEventListener("load", () => {
      setTimeout(() => {
        document.getElementById("app").style.display = "block";
        preloader.remove();
      }, 500);
    });
  }
});

// Image resizing and initialization functions
(() => {
  const defaultPageWidth = 1000;

  // Resize images with the 'autoresize' class based on the current page width
  window.resizeImages = () => {
    const scaleFactor = document.documentElement.clientWidth / defaultPageWidth;
    document.querySelectorAll("img.autoresize").forEach((image) => {
      const originalWidth =
        parseInt(image.dataset.originalWidth, 10) || image.width;
      image.dataset.originalWidth = originalWidth; // Store original width if not already set
      image.style.width = `${originalWidth * scaleFactor}px`;
    });
  };

  // Initialize images by storing their original widths
  window.initializeImages = () => {
    document.querySelectorAll("img.autoresize").forEach((image) => {
      if (!image.dataset.originalWidth) {
        image.dataset.originalWidth = image.width;
      }
    });
  };

  // Resize images on window resize
  window.addEventListener("resize", resizeImages);
})();

// Multi-step form
var currentStep = 1;
var updateProgressBar;

window.displayStep = (stepNumber) => {
  if (stepNumber >= 0 && stepNumber <= 4) {
    document.querySelector(".step-" + currentStep).style.display = "none";
    document.querySelector(".step-" + stepNumber).style.display = "block";
    currentStep = stepNumber;
    updateProgressBar();
  }
};

document.addEventListener("DOMContentLoaded", function () {
  var steps = document.querySelectorAll("#multi-step-form .step");
  steps.forEach((step, index) => {
    if (index !== 0) step.style.display = "none";
  });

  document.querySelector(".next-step").addEventListener("click", function () {
    if (currentStep < 3) {
      var currentElement = document.querySelector(".step-" + currentStep);
      currentElement.classList.add("animate__animated", "animate__fadeOutLeft");
      currentStep++;
      setTimeout(function () {
        steps.forEach((step) =>
          step.classList.remove("animate__animated", "animate__fadeOutLeft")
        );
        steps.forEach((step) => (step.style.display = "none"));
        var nextElement = document.querySelector(".step-" + currentStep);
        nextElement.style.display = "block";
        nextElement.classList.add("animate__animated", "animate__fadeInRight");
        updateProgressBar();
      }, 500);
    }
  });

  document.querySelector(".prev-step").addEventListener("click", function () {
    if (currentStep > 1) {
      var currentElement = document.querySelector(".step-" + currentStep);
      currentElement.classList.add(
        "animate__animated",
        "animate__fadeOutRight"
      );
      currentStep--;
      setTimeout(function () {
        steps.forEach((step) =>
          step.classList.remove("animate__animated", "animate__fadeOutRight")
        );
        steps.forEach((step) => (step.style.display = "none"));
        var prevElement = document.querySelector(".step-" + currentStep);
        prevElement.style.display = "block";
        prevElement.classList.add("animate__animated", "animate__fadeInLeft");
        updateProgressBar();
      }, 500);
    }
  });

  updateProgressBar = function () {
    var progressPercentage = ((currentStep - 1) / 3) * 100;
    document.querySelector(".progress-bar").style.width =
      progressPercentage + "%";
  };
});
